import { useEffect } from "react";

const Main2 = (props) => {
	useEffect(() => {
		if(props.gnbToggle){
			props.setGnbToggle(false)
		}
	},[]);
	
	return (
		<div className="contents">
			<div className="container">
				<div className="sub_title sub_title1" id="refluxis">
					<h3>REFLUX is</h3>
					<div className="intro">
						<div><p><span className="deco"></span>오랫동안 함께가는</p><p>파트너가 되겠습니다<span className="deco"></span></p></div>
						<div className="intro_bg"></div>
						<p>2003년 8월에 설립된 (주)리플럭스는 웹 프로그램 개발, 웹 디자인, SI &#47; SM, 인터넷쇼핑몰 구축 등 시스템 구축및 유지보수 분야에서 14년의 업력을 보유하고 있습니다.</p>
						<p>이러한 오랜업력의 근원은 고객과 동반자적 역할을 최우선으로 생각하는 경영정신에서 비롯되었으며, 오랫동안 함께 해 주신 고객님들의 신뢰 덕분입니다.</p>

					</div>
					<div className="intro_detail">
						<div><p>REFLUX는?</p></div>
						<ul className="intro_contents">
						<li>
							<div><span className="number"><img src={require('@img/number_01.png')} alt="1"/></span><p>다양한 비즈니스 분야의 경험을 가지고 있습니다.</p></div>
							<div>시스템 개발에서 오랜 경험은 성공확률을 높입니다. 저희는 14년의 업력만큼 다양한 분야의 많은 경험을 가지고 있습니다. 서비스를 넘어 오랜경험에서 나오는 노하우를 제공 받으십시오.</div>
						</li>
						<li>
							<div><span className="number"><img src={require('@img/number_02.png')} alt="2"/></span><p>특히 프로그램 개발을 잘 합니다</p></div>
							<div>특정 개발언어(Program Language)에 구애 받지 않습니다. 비즈니스 로직만 명확하면 개발언어는 부수적인 문제입니다. <span className="color_orange">무엇을 할까?</span>만 준비해 주십시오.<br/>나머지는 저희가 안내해 드리겠습니다.</div>
						</li>
						<li>
							<div><span className="number"><img src={require('@img/number_03.png')} alt="3"/></span><p>다양한 시스템 환경을 잘 알고 있습니다</p></div>
							<div>시스템개발은 사업 분 및 규모에 따라서 개발언어, 네트워크 환경, 서버, WAS, DBMS, UI&#47;UX 등 다양한 환경에 최적화 되어 있습니다.<br/>고객님의 비즈니스 분야와 예산 범위 내에서 최선의 시스템을 제안해 드리겠습니다.</div>
						</li>
						</ul>
					</div>
				</div>
				<div className="sub_title sub_title2" id="whatwedo">
					<h3>What we do?</h3>
					<div className="intro">
						<p>(주)리플럭스는 2003년에 설립하여, 공공기관과 민간기업의 SI, SM, 웹사이트 구축, E-Biz 솔루션(인터넷 쇼핑몰) 개발 등의 사업을 전개하고 있습니다.</p>
					</div>
					<div className="intro_detail">
						<ul className="intro_contents2">
							<li>
								<div className="intro_contents_bg sub_bg_02"></div>
								<div className="intro_contents_txt">
								<p>프로그램 <span className="color_orange">신규개발</span></p>
								홈페이지, 인터넷 쇼핑몰, 업무시스템, 통계관리 시스템 등 웹(WEB)상에서 이루어지는 다양한 비즈니스 분야의 프로그램을 개발합니다.<br/><br/>
								주 개발 언어는 java/jsp, php, asp, c#(.net)
								</div>
							</li>
							<li>
								<div className="intro_contents_bg sub_bg_03"></div>
								<div className="intro_contents_txt">
								<p>프로그램 <span className="color_orange">유지보수</span></p>
								저희는 시스템을 잘 알고 있습니다. 이에 개발언어와 무관하게 프로그램 유지보수가 가능합니다.<br/>
								시스템을 유지 관리할 인력이 부족하거나 아웃소싱이 필요할 경우 언제든지 문의하여 주십시오.
								</div>
							</li>
							<li>
								<div className="intro_contents_bg sub_bg_04"></div>
								<div className="intro_contents_txt">
								<p>웹디자인</p>
								디자인을 위한 디자인이 아닌 꼭 필요한 디자인, 편리한 디자인, 확장가능한 디자인을<br/>
								추구합니다.
								</div>
							</li>
							<li>
								<div className="intro_contents_bg sub_bg_05"></div>
								<div className="intro_contents_txt">
								<p>웹 퍼블리싱</p>
								N-SCREEN 시대에 걸맞는 웹 표준, 웹 접근성, 반응형 퍼블리싱을 통하여 누구나, 언제, 어디서나<br/>
								사이트에 편리하게 접근할 수 있는 퍼블리싱을 구현합니다.
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div className="sub_title sub_title3" id="news">
					<h3>News &amp; notice</h3>
					<div className="intro_detail3">
						<ul className="intro_contents3">
							<li>
								<p>홈페이지를 새롭게 오픈하였습니다.</p>
								바쁘게 일만 하다 보니 우리 홈페이지를 돌볼 겨를이 없었네요. 많은 고객분들의 요청에 따라 홈페이지를 새롭게 개편하였습니다.<br/>
								앞으로 더 많은 서비스로 찾아 뵙겠습니다.
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}
  
export default Main2;
  