import { Link } from "react-router-dom";


const Header = (props) => {
	//console.log(props.gnbToggle);

	

	return (
		<div className="header">
			<div className="container">
				<div className="logo">
					<h1>
						<Link to="/" ><img src={require('@img/logo.png')} alt="로고"/></Link>
					</h1>
				</div>
				<div className={`menu_btn ${props.gnbToggle === true ? 'on': ''}`} onClick={props.onGnbToggle}><span className="hidden">메뉴보기</span></div>
				<div className={`mobile_nav_wrap ${props.gnbToggle === true ? 'on': ''}`}>
					<ul className="mobile_nav_list">
						<li className="border_none">
							<Link to="/AboutUs" className="slide">About us </Link>
							<ul className="mobile_nav_detail">
								<li><Link to="/AboutUs#refluxis">REFLUX is</Link></li>
								<li><Link to="/AboutUs#whatwedo">What we do?</Link></li>
								<li><Link to="/AboutUs#news">News &amp; notice</Link></li>
							</ul>
						</li>
						<li>
							<Link to="/Works" className="slide">Works </Link>
							<ul className="mobile_nav_detail">
								<li><Link to="/Works#recentproject">Recent project</Link></li>
								<li><Link to="/Works#portfolio">Portfolio</Link></li>
							</ul>
						</li>
						<li>
							<Link to="/Contact" className="slide">Contact us </Link>
							<ul className="mobile_nav_detail">
								<li><Link to="/Contact">Request project</Link></li>
								<li><Link to="/#contactus">Location</Link></li>
							</ul>
						</li>
						<li><Link to="/Recruit">Recruit</Link></li>
					</ul>
				</div>
				<div className="nav_wrap">
					<h2 className="hidden">네비게이터</h2>
					<ul className="nav_list">
						<li>
							<Link to="/AboutUs">About us</Link>
							{/* <Link to={{
								pathname: "/AboutUs",
								search: "?sort=name",
								hash: "#the-hash",
								state: { fromDashboard: true }
							}}>About us</Link> */}
							<ul className="nav_detail">
								<span className="deco"></span>
								<li><Link to="/AboutUs#refluxis">REFLUX is</Link></li>
								<li><Link to="/AboutUs#whatwedo">What we do?</Link></li>
								<li><Link to="/AboutUs#news">News &amp; notice</Link></li>
							</ul>
						</li>
						<li>
							<Link to="/Works">Works</Link>
							<ul className="nav_detail">
								<span className="deco"></span>
								<li><Link to="/Works#recentproject">Recent project</Link></li>
								<li><Link to="/Works#portfolio">Portfolio</Link></li>
							</ul>
						</li>
						<li>
							<Link to="/Contact">Contact us</Link>
							<ul className="nav_detail">
								<span className="deco"></span>
								<li><Link to="/Contact">Request project</Link></li>
								<li><Link to="/#contactus">Location</Link></li>
							</ul>
						</li>
						<li><Link to="/Recruit">Recruit</Link></li>
					</ul>
				</div>
			</div>
		</div>
	);
}
	
export default Header;
	