import { useEffect } from "react";
import { Link } from 'react-router-dom';
import '@styles/main.css';

const Main = (props) => {
	useEffect(() => {
		if(props.gnbToggle){
			props.setGnbToggle(false)
		}
	},[]);

	// <!-- 3. 실행 스크립트 -->
	const executeScript = () => {
		//alert("executeScript");
		const scriptTag = document.createElement("script");
		const inlineScript = document.createTextNode(`new daum.roughmap.Lander({
			"timestamp" : "1511925492050",
			"key" : "koh9",
			"mapWidth" : "1250",
			"mapHeight" : "300"
		}).render();`);
		scriptTag.appendChild(inlineScript);
		document.body.appendChild(scriptTag);
	};
	
	// <!-- 2. 설치 스크립트 * 지도 퍼가기 서비스를 2개 이상 넣을 경우, 설치 스크립트는 하나만 삽입합니다. -->
	// document.write 문제가 발생해서 해당 파일을 직접 가져온다음 수정했음
	const InstallScript = () => {
		if (
			document.querySelector(
			  `script[src*="//t1.daumcdn.net/kakaomapweb/place/jscss/roughmap/"]`
			)
		  ){
			executeScript();
			return;
		  }else{
			//executeScript();
		  }
			
		(function () {
			//alert("installScript");
			var c = window.location.protocol == "https:" ? "https:" : "http:";
			var a = "16137cec";

			if (window.daum && window.daum.roughmap && window.daum.roughmap.cdn) {
				return;
			}
			window.daum = window.daum || {};
			window.daum.roughmap = {
				cdn: a,
				URL_KEY_DATA_LOAD_PRE: c + "//t1.daumcdn.net/roughmap/",
				url_protocal: c,
			};
			var b =
			c +
			"//t1.daumcdn.net/kakaomapweb/place/jscss/roughmap/" +
			a +
			"/roughmapLander.js";
 
			// document.write -> doumnet.body.append로 수정
			const scriptTag = document.createElement("script");
			scriptTag.src = b;
			document.body.append(scriptTag);
			scriptTag.onload = () => {
				executeScript();
			};
		})();
	};
	
	useEffect(() => {
		InstallScript();
		//executeScript();
	}, []);

    return (
        <div className="contents">

			<div className="content since">
				<div className="container">
				<div className="left_txt">
					<h3 className="hidden">Since 2003</h3>
					<p className="content_title"><span>(주)리플럭스 2003년 설립하여,</span><span>공공기관과 민간기업의 SI,SM,웹사이트 구축,</span><span>E-Biz 솔루션(인터넷쇼핑몰) 개발 등의</span><span>사업을 전개하고 있습니다.</span></p>
					<Link to="/AboutUs"><span>REFLUX is</span><i></i></Link>
				</div>
				<div className="txt_title"><p>Since 2003</p></div>
				</div>
			</div>

			<div className="content programming">
				<div className="container">
				<div className="left_txt">
					<h3 className="hidden">Programming</h3>
					<p className="content_title"><span>고객의 Needs에 맞는 개발언어와 환경으로</span><span>최적의 프로그램 개발을 약속 드립니다.</span></p>
					<p className="plus_txt">Java,Jsp,c#,.net,asp,php</p>
					<Link to="/Works"><span>View more</span><i></i></Link>
					<div className="content_img">
					<img src={require('@img/main_content_02.png')} alt="Java,Jsp,c#,.net,asp,php"/>
					</div>

				</div>
				<div className="txt_title"><p>Programming</p></div>
				</div>
			</div>

			<div className="content managment">
				<div className="container">
				<div className="left_txt">
					<h3 className="hidden">System Management</h3>
					<p className="content_title"><span>최신 개발언어/환경은 물론이고,</span><span>몇 년이 지나 많이 사용하지 않는 개발 언어/환경의 시스템 유지관리,</span><br/><b>(주)리플럭스에서 상담 받으십시오.</b></p>
					<Link to="/Contact"><span>Request project</span><i></i></Link>
				</div>
				<div className="txt_title">
					<p className="left_align">System</p>
					<p>Managment</p>
				</div>
				</div>
			</div>

			<div className="content integration">
				<div className="container">
				<div className="left_txt">
					<h3 className="hidden">System Integration</h3>
					<p className="content_title"><span><em>SI</em>(System integration)란 기업 및 공공기관의 정보시스템 구축에 있어</span><span>기획, 개발, 구축, DB 통합, 시스템 통합, 시스템연계 등</span><span>모든 서비스를 통합적으로 제공하는 것을 말합니다.</span></p>
					<Link to="/Works#portfolio"><span>View more</span><i></i></Link>
				</div>
				<div className="txt_title">
					<p className="left_align">System</p>
					<p>Integration</p>
				</div>
				</div>
			</div>

			<div className="content publishing">
				<div className="container">
				<div className="left_txt">
					<h3 className="hidden">Web Publising</h3>
					<p className="content_title"><span>어디서나 누구나 편리하게 인터넷정보를 누릴수 있는</span> <span>코딩으로 정보 소외계층을 줄이겠습니다.</span></p>
					<Link to="/AboutUs#whatwedo"><span>View more</span><i></i></Link>
					<div className="content_img">
					<ul>
						<li><img src={require('@img/main_content_05_01.png')} alt="HTML"/></li>
						<li><img src={require('@img/main_content_05_02.png')} alt="CSS"/></li>
						<li><img src={require('@img/main_content_05_03.png')} alt="웹표준"/></li>
						<li><img src={require('@img/main_content_05_04.png')} alt="웹접근성"/></li>
					</ul>
					</div>

				</div>
				<div className="txt_title">
					<p className="left_align">Web
					</p>
					<p>Publishing</p>
				</div>
				</div>
			</div>

			<div className="content solution">
				<div className="container">
				<div className="left_txt">
					<h3 className="hidden">Web Solution</h3>
					<p className="content_title"><span><em>웹솔루션</em><b>(web solution)</b>은 범용적으로 사용가능하고,</span> <span>나아가 고객의 업무특성에 맞게 커스터마이징(Customizing)이</span> <span>가능하도록 확장성을 보유한 프로그램을 말합니다.</span></p>
					<Link to="/Contact"><span>Request project</span><i></i></Link>
					<div className="content_img">
					<ul>
						<li className="img_box"><img src={require('@img/main_content_06_01.png')} alt="CMS"/></li>
						<li><p><span>CMS란?</span> 콘텐츠 관리 시스템(Contents Management System)으로 사전적인 의미로는 다양한 포맷의 콘텐츠인 문서, 이미지, 동영상, 사운드 등을 관리하는 솔루션을 말하며 일반적으로는 콘텐츠 관리뿐만 아니라 홈페이지에 필요한 필수 기능을 추가하여 CMS라고도 합니다.</p></li>
					</ul>
					<ul className="left_content">
						<li className="img_box"><img src={require('@img/main_content_06_02.png')} alt="인터넷쇼핑몰"/></li>
						<li><p><span>인터넷 쇼핑몰이란?</span> 인터넷을 이용하여 물건을 팔고 살 수 있는 가상공간의 상점을 말합니다. 판매자의 수에 따라서 독립형과 입점형으로 나눌 수있으며, 고객에 따라서 B2B,B2C몰로 나뉩니다.오픈 여부에 따라서 오픈형과 폐쇄형 쇼핑몰로 구분하기도 합니다.</p></li>
					</ul>
					</div>
				</div>
				<div className="txt_title">
					<p>Web Solution</p>
				</div>
				</div>
			</div>

			<div className="content map" id="contactus">
				<div className="container">
				<div className="map_box">
					<div id="daumRoughmapContainer1511925492050" className="root_daum_roughmap root_daum_roughmap_landing"></div>
				</div>
				
				<div className="left_txt">
					<h3 className="hidden">Contact Us</h3>
					<p className="map_txt"><span>주소</span> 서울특별시 강남구 역삼로 138, 6층(역삼동, 동광빌딩)</p>
					<p className="map_txt web_phone"><span>전화</span> 02-591-5274 <span>팩스</span> 0507-489-5274</p>
					<p className="map_txt mobile_phone"><span>전화</span> <a href="tel:02-591-5274">02-591-5274</a> <span>팩스</span> 0507-489-5274</p>
					<p className="map_txt"><span>이메일</span> bjkim@reflux.co.kr</p>
				</div>
				<div className="txt_title">
					<p>Contact Us</p>
				</div>
				</div>
			</div>

      	</div>
    );
}
  
export default Main;
  