import { useEffect } from "react";

const NotFound = (props) => {
	useEffect(() => {
		if(props.gnbToggle){
			props.setGnbToggle(false)
		}
	},[]);
	
	return (
    	<div style={{padding:"300px",fontSize:"50px",fontWeight:"bold"}}>Not Found!!!!</div>
	);
}
  
export default NotFound;
  