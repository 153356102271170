import { useEffect } from "react";
const Works = (props) => {
	useEffect(() => {
		if(props.gnbToggle){
			props.setGnbToggle(false)
		}
	},[]);

	return (
		<div className="contents">
			<div className="container">
				<div className="sub_title sub_title1" id="recentproject">
				<h3>Recent project</h3>
				<div className="sub_content">
					<ul className="project_list">
					<li>- OO제약 쇼핑몰 2차 추가 개발<div><span>java</span><span>jsp</span></div></li>
					<li>- 한양대학교 의료원 통합 홈페이지 구축<div><span>java</span><span>jsp</span></div></li>
					<li>- (주)OO 글로벌 쇼핑몰 구축<div><span>java</span><span>jsp</span></div></li>
					<li>- OO제약 폐쇄형 쇼핑몰 구축<div><span>java</span><span>jsp</span></div></li>
					<li>- 법무법인 율촌 홈페이지 유지보수<div><span>asp</span></div></li>
					<li>- 서울시 OO 홈페이지 유지보수<div><span>java</span><span>jsp</span></div></li>
					<li>- 한국 OO 정보원 시스템 구축<div><span>java</span><span>jsp</span></div></li>
					</ul>
					<ul className="project_info">
					<li>일부 프로젝트는 고객사 비밀 유지를 위해 OOO 처리 하였습니다.</li>
					<li>자세한 사항은 문의 주시면 성심 성의껏 상담해 드리겠습니다.</li>
					</ul>
				</div>

				</div>
				<div className="sub_title sub_title2" id="portfolio">
				<h3>Portfolio</h3>
				<div className="sub_content">
					<p style={{textAlign:"center",margin:"20px 0 100px 0", padding:"20px 10px", border:"1px solid #e9e9e9", fontWeight:"bold", fontSize: "20px"}}>준비중입니다.</p>
				</div>
				</div>
			</div>
		</div>
	);
}
  
export default Works;
  