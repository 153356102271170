import { useEffect } from "react";
const Recruit = (props) => {
	useEffect(() => {
		if(props.gnbToggle){
			props.setGnbToggle(false)
		}
	},[]);

	return (
		<div className="contents">
			<div className="container">
				<div className="sub_title sub_title1">
					<h3>Recruit</h3>
					<div className="sub_content">
						<div className="recruit">
						<p>(주)리플럭스에서는 아래와 같이 실력있는 직원을 모집합니다.</p>
						<ul>
							<li><span className="content_title">모집기간</span><span className="content_detail">상시채용</span></li>
							<li><span className="content_title">고용형태</span><span className="content_detail">정규직, 계약직, 프리랜서</span></li>
							<li><span className="content_title">복리후생</span><span className="content_detail">4대보험, 주5일근무, 월차, 경조사 지원</span></li>
							<li>
							<p className="web_phone">
								<span className="content_title">전&nbsp;&nbsp;화</span>
								<span className="content_detail">02-591-5274</span>
							</p>
							<p className="mobile_phone">
								<span className="content_title">전&nbsp;&nbsp;화</span>
								<span className="content_detail"><a href="tel:02-591-5274">02-591-5274</a></span>
							</p>
							</li>
							<li><span className="content_title">이 메 일</span><span className="content_detail">bjkim@reflux.co.kr</span></li>
							<li><span className="content_title">모집분야</span><span className="content_detail">웹프로그래머, 웹 디자이너, 웹 퍼블리셔, 웹 기획</span></li>
						</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
  
export default Recruit;
  