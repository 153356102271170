import axios from "axios";
import React, { useState, useEffect } from 'react';

const Contact = (props) => {
	const [contactInfo, setContactInfo] = useState({
		company: "",
		url: "",
		manager: "",
		department: "",
		job: "",
		phone1: "",
		phone2: "",
		phone3: "",
		mobile1: "",
		mobile2: "",
		mobile3: "",
		emailName: "",
		emailAddr: "",
		address: "",
		contents: "",
		ddate: ""
	});

	useEffect(() => {
		if(props.gnbToggle){
			props.setGnbToggle(false)
		}
	},[]);

	const onChangeContact = (e) => {
		const { value, name } = e.target;
		setContactInfo({
			...contactInfo, // 기존의 input 객체를 복사한 뒤
			[name]: value // name 키를 가진 값을 value 로 설정
		 });
	};

	const onClearContact = () => {
		setContactInfo(
			{
				company :"",
				url:"" ,
				manager :"",
				department :"",
				job :"",
				phone1 :"", 
				phone2 :"", 
				phone3 :"", 
				mobile1 :"", 
				mobile2 :"", 
				mobile3 :"", 
				emailName :"", 
				emailAddr :"", 
				address :"", 
				contents :"", 
				ddate :""
			}
		);
	};

	const handleSubmit = () => {
		axios.post("https://www.reflux.co.kr/text", {
            company: contactInfo.company,
            url: contactInfo.url,
            manager: contactInfo.manager,
            department: contactInfo.department, 
			job: contactInfo.job,
			phone: contactInfo.phone1+'-'+contactInfo.phone2+'-'+contactInfo.phone3,
			mobile: contactInfo.mobile1+'-'+contactInfo.mobile2+'-'+contactInfo.mobile3,
			email: contactInfo.emailName+'@'+contactInfo.emailAddr,
			address: contactInfo.address,
			contents: contactInfo.contents,
			ddate: contactInfo.ddate
        }).then((res)=>{
			if(res.status == '200'){
				alert(res.data);
				onClearContact();
			}
		})
		.catch((e) => console.log('something went wrong :(', e));
	}

	return (
    	<div className="contents">
			<div className="container">
				<div className="sub_title sub_title1">
				<h3>Request project</h3>
				<div className="sub_content">
					<p>프로젝트 상담문의 : 02-591-5274</p>
					<p> · 담당자에게 연락을 주시거나 아래 항목을 작성해 주시면 검토 후 연락 드리겠습니다.</p>

					<form name="contact" method="post">
					<table className="consult_table">
						<caption>프로젝트 상담문의</caption>
						<tbody>
						<tr>
							<th>회사명&nbsp;<span>*</span></th>
							<td><input type="text" id="company" name="company" onChange={onChangeContact} value={contactInfo.company}/></td>
						</tr>
						<tr>
							<th>웹사이트 URL</th>
							<td><input type="text" id="url" name="url" onChange={onChangeContact} value={contactInfo.url}/></td>
						</tr>
						<tr>
							<th>담당자&nbsp;<span>*</span></th>
							<td><input type="text" id="manager" name="manager" onChange={onChangeContact} value={contactInfo.manager}/></td>
						</tr>
						<tr>
							<th>부서/직책</th>
							<td>
							<input type="text" id="department" name="department" onChange={onChangeContact} value={contactInfo.department}/> / <input type="text" id="job" name="job" onChange={onChangeContact} value={contactInfo.job}/>
							</td>
						</tr>
						<tr>
							<th>전화번호</th>
							<td>
							<input type="text" id="phone1" name="phone1" onChange={onChangeContact} value={contactInfo.phone1}/>
							- <input type="text" id="phone2" name="phone2" onChange={onChangeContact} value={contactInfo.phone2}/> - <input type="text" id="phone3" name="phone3" onChange={onChangeContact} value={contactInfo.phone3}/>
							</td>
						</tr>
						<tr>
							<th>휴대전화&nbsp;<span>*</span></th>
							<td>
							<input type="text" id="mobile1" name="mobile1" onChange={onChangeContact} value={contactInfo.mobile1}/>
							- <input type="text" id="mobile2" name="mobile2" onChange={onChangeContact} value={contactInfo.mobile2}/> - <input type="text" id="mobile3" name="mobile3" onChange={onChangeContact} value={contactInfo.mobile3}/>
							</td>
						</tr>
						<tr>
							<th>이메일&nbsp;<span>*</span></th>
							<td>
							<span className="emailbox"><input type="text" id="emailName" name="emailName" onChange={onChangeContact} value={contactInfo.emailName}/> @ <input type="text" id="emailAddr" name="emailAddr" onChange={onChangeContact} value={contactInfo.emailAddr}/></span>
							</td>
						</tr>
						<tr>
							<th>회사주소</th>
							<td><input type="text" id="address" name="address" onChange={onChangeContact} value={contactInfo.address}/></td>
						</tr>
						<tr className="content_project">
							<th>프로젝트 내용</th>
							<td><textarea id="contents" name="contents" rows="5" onChange={onChangeContact} value={contactInfo.contents}></textarea></td>
						</tr>
						<tr>
							<th>완료 예정일</th>
							<td><input type="text" id="ddate" name="ddate" onChange={onChangeContact} value={contactInfo.ddate}/></td>
						</tr>
						{/* <tr className="content_file">
							<th className="content_file_title">RFP 첨부</th>
							<td>
							<label>
								파일 선택
								<input type="file" name="file" id="file" onChange={(e) => setFileNm(e.target.value)} />
							</label>
							<input type="text" readOnly title="File Route" id="file_route" value={fileNm}/>
							</td>
						</tr> */}
						</tbody>
					</table>
					<div className="button_wrap">
						<div className="buttons">
						<button type="button" className="check" style={{marginRight:"5px"}} onClick={handleSubmit}>확인</button>
						<button type="button" onClick={onClearContact}>다시쓰기</button>
						</div>
					</div>
					</form>
				</div>
				</div>
			</div>
		</div>
	);
}
  
export default Contact;
  