const Footer = () => {
    return (
		<div className='footer'>
			<div className='container ft_wrap'>
				<div className='ft_logo'><img src={require('@img/logo2.png')} alt="리플럭스"/></div>
				<div className='ft_content'>
				<p>주소: 서울특별시 강남구 역삼로 138, 6층 (역삼동, 동광빌딩) 회사명: (주)리플럭스 사업자번호: 114-86-29620</p>
				<p className='web_phone'>전화: 02-591-5274 팩스: 0507-489-5274</p>
				<p className='mobile_phone'>전화: <a href="tel:02-591-5274">02-591-5274</a> 팩스: 0507-489-5274</p>
				<p>Copyright&copy;www.reflux.co.kr ALL right reseved.</p>
				</div>
			</div>
		</div>
    );
  }
  
  export default Footer;
  