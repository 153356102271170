import { React, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from '@components/App';
import ScrollToTop from '@utils/ScrollToTop';
import ScrollHandle from '@utils/ScrollHandle';
import {BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
		<Suspense fallback={<div>Loading...</div>}>
			<ScrollToTop />
			<ScrollHandle />
			<App />
		</Suspense>
	</BrowserRouter>
);