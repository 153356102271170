import { Route, Routes } from 'react-router-dom';
import NotFound from '@pages/NotFound';
import Header from '@components/Header';
import Footer from '@components/Footer';
import Main from '@pages/Main';
import AboutUs from '@pages/AboutUs';
import Works from '@pages/Works';
import Contact from '@pages/Contact';
import Recruit from '@pages/Recruit';
import '@styles/reset.css';
import '@styles/common.css';
import '@styles/content.css';
import '@styles/mobile.css';
import React, { useState } from 'react';

const App = () => {
	const [gnbToggle, setGnbToggle] = useState(false);
	const onGnbToggle = () => {
		if( gnbToggle ){
			setGnbToggle(false)
		}else{
			setGnbToggle(true)
		}
	};

	return (
		<>
			<main className='main'>
				<Header gnbToggle={gnbToggle} onGnbToggle={onGnbToggle}/>
				<Routes>
					{/* <Route path="/" render={props => <Main onGnbToggle={onGnbToggle} {...props}/> } /> */}
					{/* <Route exact path='/' render={() => <Main onGnbToggle={onGnbToggle}/>} /> */}
					<Route exact path='/' element={<Main gnbToggle={gnbToggle} setGnbToggle={setGnbToggle}/>} 
					></Route>
					{/* <Route path='/AboutUs' render={() => <AboutUs gnbToggle={gnbToggle} onGnbToggle={onGnbToggle}/>} /> */}
					<Route path='/AboutUs' element={<AboutUs gnbToggle={gnbToggle} setGnbToggle={setGnbToggle}/>} ></Route>
					<Route path='/Works' element={<Works gnbToggle={gnbToggle} setGnbToggle={setGnbToggle}/>} ></Route>
					<Route path='/Contact' element={<Contact gnbToggle={gnbToggle} setGnbToggle={setGnbToggle}/>} ></Route>
					<Route path='/Recruit' element={<Recruit gnbToggle={gnbToggle} setGnbToggle={setGnbToggle}/>} ></Route>
					<Route path='*' element={<NotFound gnbToggle={gnbToggle} setGnbToggle={setGnbToggle}/>} ></Route>
				</Routes>
				<Footer />
			</main>
		</>
	);
}

export default App;
